<template>
  <div>
    <DropdownBase
      x-anchor="false"
      custom-classes="mt-2"
      :id="dropdownId"
      :style="dropdownWidth ? `width: ${dropdownWidth} !important` : ''"
      :custom-styles="dropdownMargin"
      :class="`overflow-x-hidden overflow-y-hidden ${dropdownClass}`"
    >
      <div class="p-1" v-if="search">
        <input
          v-model="searchTerm"
          class="w-full border border-gray-700 bg-gray-900 p-0.5 px-1 py-1 focus:border-gray-700 focus:outline-none"
          placeholder="Search"
        />
      </div>
      <div
        class="ml-1 mt-1 flex flex-col overflow-y-auto overflow-x-hidden px-1"
        :class="!multiple && selectedItem == 'custom_range' ? '' : 'max-h-40'"
      >
        <div class="flex flex-col" v-if="multiple">
          <div v-for="(item, i) in filteredItems" :key="item.id" class="mb-2 mt-1 flex items-center">
            <BaseCheckbox
              :id="`item_${i}`"
              v-model="item.selected"
              @update:model-value="selected.includes(item.id) ? $emit('removed', item.id) : $emit('selected', item.id)"
            >
              <div
                v-if="item.html"
                v-html="item.html"
                class="text-md block w-full cursor-pointer select-none bg-gray-800 px-2 hover:bg-blue-900/50 hover:text-white"
              ></div>
              <span v-else class="w-[90%] break-words text-xs font-normal leading-4 hover:text-white">
                {{ item.name }}
              </span>
            </BaseCheckbox>
          </div>
          <div
            v-if="!searchTerm && filteredItems.length && !filteredItems[0]?.html"
            class="sticky bottom-0 w-full cursor-pointer space-y-1 bg-gray-800 py-1 text-xs font-normal leading-4 text-gray-400 hover:text-white"
            @click="$emit('clear')"
          >
            Clear All
          </div>
        </div>
        <div v-else>
          <div v-for="(item, i) in filteredItems" :key="item.id" class="mb-2 mt-1 flex items-center">
            <label :for="`item_${i}`" class="flex cursor-pointer items-center text-sm text-gray-300 hover:text-white">
              <input
                v-model="selectedItem"
                class="mr-1 font-medium"
                type="radio"
                :id="`item_${i}`"
                :checked="selected && selected.includes(item.id)"
                :value="item.id"
              />
              <span class="break-words text-xs font-normal leading-4"> {{ item.name }} </span>
            </label>
          </div>
          <div class="relative" v-if="selectedItem == 'custom_range'">
            <div class="w-full text-xs">
              <ButtonDatepicker
                format="MMM, DD"
                placeholder="Start Date ~ End Date"
                font-size="text-xs"
                custom-style="width: 13rem; max-width: 13rem"
                :range="true"
                :key="selectedRange"
                :default-date="selectedRange"
                @change="
                  (date, type) => {
                    selectedRange = date;
                  }
                "
              />
            </div>
          </div>
          <div
            v-if="!searchTerm"
            class="mb-2 mt-1 cursor-pointer text-xs font-normal leading-4 text-gray-400 hover:text-white"
            @click="clearDate()"
          >
            Clear All
          </div>
        </div>
      </div>
      <div v-if="filteredItems.length === 0" class="px-5 py-5 text-center text-gray-400">No Result Found</div>
    </DropdownBase>
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
import DropdownBase from '@/components/dropdown/DropdownBase.vue';
import ButtonDatepicker from '@/components/ButtonDatepicker.vue';

const props = defineProps({
  items: Array,
  dropdownWidth: String,
  dropdownClass: String,
  dropdownMargin: String,
  selected: Array,
  selectedDateRange: Array,
  multiple: Boolean,
  search: {
    type: Boolean,
    default: false
  },
  searchText: String
});
const $emit = defineEmits(['selected-date-range', 'selected', 'close', 'clear', 'removed']);

// SEARCH
const searchTerm = ref('');
const filteredItems = computed(() => {
  return searchTerm.value.trim().length > 0
    ? editableItems.value.filter(i => (i.name ? i.name.toLowerCase().includes(searchTerm.value.toLowerCase()) : false))
    : editableItems.value;
});
watch(
  () => props.searchText,
  (newVal, oldVal) => {
    searchTerm.value = props.searchText;
  }
);

// SELECTED DATA
const selectedItem = ref('');
const editableItems = ref([]);
function setEditableItems() {
  editableItems.value = props.items.map(i => {
    return {
      ...i,
      selected: props.selected?.includes(i.id) || false
    };
  });
}
onMounted(() => {
  if (props.selected && props.selected.length > 0 && !props.multiple) {
    selectedItem.value = props.selected[0];
  }
  setEditableItems();
});
watch(
  () => props.items,
  () => {
    setEditableItems();
  }
);
watch(
  () => selectedItem.value,
  (newVal, oldVal) => {
    if (selectedItem.value !== '') {
      $emit('selected', selectedItem.value);
    }
  }
);

const selectedRange = ref([]);
onMounted(() => {
  if (props.selectedDateRange && props.selectedDateRange.length > 0) {
    selectedRange.value = props.selectedDateRange;
  }
});
watch(
  () => selectedRange.value,
  (newVal, oldVal) => {
    if (selectedRange.value.length > 0) {
      $emit('selected-date-range', selectedRange.value);
    }
  }
);

// DROPDOWN
const dropdownId = ref(`dropdown_${Math.random()}`);
const droppedDown = ref(false);

function close(e) {
  const drop = document.getElementById(dropdownId.value);
  const datepickerBody = document.getElementsByClassName('mx-datepicker-content');
  const datepicker = document.getElementsByClassName('datepicker');
  let datePickerClicked = datepicker.length > 0 ? datepicker[0].contains(e.target) : false;
  let datepickerBodyClicked = datepickerBody.length > 0 ? datepickerBody[0].contains(e.target) : false;
  if (
    !drop.contains(e.target) &&
    droppedDown.value &&
    e.target.id !== dropdownId.value &&
    !datePickerClicked &&
    !datepickerBodyClicked
  ) {
    droppedDown.value = false;
    $emit('close');
  } else {
    droppedDown.value = true;
  }
}

// OTHERS
function clearDate() {
  selectedItem.value = '';
  selectedRange.value = [];
  $emit('clear');
}

onMounted(() => {
  document.addEventListener('click', close);
});

onUnmounted(() => {
  document.removeEventListener('click', close);
});
</script>
